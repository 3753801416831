<template>
  <div class="classroom">
    <div class="content1" />
    <div class="content2">
      <div class="title" style="padding-top: 100px">
        <img class="titleIcon" src="@/style/image/classroom/titleIcon.png">
        <div><span class="blue">互联网+大数据&nbsp;</span>智能融合</div>
      </div>
      <div class="content">
        <div class="content-left">
          <div class="title">教学过程智能服务</div>
          <div class="left-content">
            <div class="item">
              <img class="itemIcon" src="@/style/image/classroom/no1.png">
              <div class="box">
                <div class="box-title">智能教学规划</div>
                <div class="box-text">教学大纲，大数据智能反馈，指导教学重点</div>
              </div>
            </div>
            <div class="item-line" />
            <div class="item">
              <img class="itemIcon" src="@/style/image/classroom/no2.png">
              <div class="box">
                <div class="box-title">智能随堂测练</div>
                <div class="box-text">
                  <div>课后随堂、课程模考边学边练，</div>
                  <div>随时检验，查缺补漏。</div>
                </div>
              </div>
            </div>
            <div class="item-line" />
            <div class="item">
              <img class="itemIcon" src="@/style/image/classroom/no3.png">
              <div class="box">
                <div class="box-title">智能题库服务</div>
                <div class="box-text">月考推送，错题收集，巩固提高。</div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-right">
          <div class="title">课堂特点</div>
          <div class="right-content">
            <div class="item">
              <img class="itemIcon" src="@/style/image/classroom/right1.png">
              <div class="text">教学决策数据化</div>
            </div>
            <div class="item">
              <img class="itemIcon" src="@/style/image/classroom/right2.png">
              <div class="text">评价反馈及时化</div>
            </div>
            <div class="item m-t-40">
              <img class="itemIcon" src="@/style/image/classroom/right3.png">
              <div class="text">交流互动立体化</div>
            </div>
            <div class="item m-t-40">
              <img class="itemIcon" src="@/style/image/classroom/right4.png">
              <div class="text">资源推送智能化</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="title" style="padding-top: 100px">
        <img class="titleIcon" src="@/style/image/classroom/titleIcon.png">
        <div>
          <span class="blue">移动课堂</span>
          &nbsp;智能学习助力通关
        </div>
      </div>
      <div class="content yidongClass">
        <!-- <div class="box1 box">
          <div class="text">录播课程</div>
          <div class="text">直播课程</div>
          <div class="text">直播回放</div>
          <div class="text">随时随地刷课学习</div>
        </div>
        <div class="box2 box">
          <div class="text">随堂练习</div>
          <div class="text">课程模考</div>
          <div class="text">聚题库小程序随时演练刷题</div>
        </div>
        <div class="box3 box">
          <div class="text">课程讲义</div>
          <div class="text">考前辅导</div>
          <div class="text">串讲资料分类快速查找</div>
        </div>
        <div class="box4 box">
          <div class="text">考前通知</div>
          <div class="text">考试资讯</div>
          <div class="text">备考报考经验分享</div>
        </div> -->
      </div>
    </div>
    <div class="content4">
      <div class="title" style="padding-top: 100px">
        <img class="titleIcon" src="@/style/image/classroom/titleIcon.png">
        <div>
          聚题库平台上线 &nbsp;
          <span class="blue">知识点+试题</span>强化学习
        </div>
      </div>
      <div class="content">
        <div class="content-item item1">
          <div class="item-title">聚题库</div>
          <div class="item-tips">依托大数据分析 录入各类考点</div>
          <img class="titleIcon" src="@/style/image/classroom/kaodian1.png">
          <div class="list">
            <div class="item">
              <img class="item-icon" src="@/style/image/classroom/kdIcon1.png">
              <div class="text">考点归纳</div>
            </div>
            <div class="item">
              <img class="item-icon" src="@/style/image/classroom/kdIcon1.png">
              <div class="text">命题分析</div>
            </div>
            <div class="item">
              <img class="item-icon" src="@/style/image/classroom/kdIcon1.png">
              <div class="text">考点梳理</div>
            </div>
            <div class="item">
              <img class="item-icon" src="@/style/image/classroom/kdIcon1.png">
              <div class="text">预析考点</div>
            </div>
          </div>
        </div>
        <div class="content-item item2">
          <div class="item-title">聚题库</div>
          <div class="item-tips">分析试题 归纳题型 解析知识点</div>
          <img class="titleIcon" src="@/style/image/classroom/kaodian2.png">
          <div class="list">
            <div class="item">
              <img class="item-icon" src="@/style/image/classroom/kdIcon2.png">
              <div class="text">专项试题组成</div>
            </div>
            <div class="item">
              <img class="item-icon" src="@/style/image/classroom/kdIcon2.png">
              <div class="text">命题分析</div>
            </div>
            <div class="item">
              <img class="item-icon" src="@/style/image/classroom/kdIcon2.png">
              <div class="text">题型归纳</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  <div class="content5">
      <div class="title" style="padding-top: 100px">
        <img class="titleIcon" src="@/style/image/classroom/titleIcon.png" />
        <div>AI智能诊断学情&nbsp;人机<span class="blue">智能答疑</span></div>
      </div>
      <div class="content">
        <div class="item">
          <img class="titleIcon" src="@/style/image/classroom/itemIcon1.png" />
          <div class="item-title">智能题库AI诊断</div>
          <div class="item-text">
            根据进度智能匹配习题，强化练习并巩固知识点；大数据检测反馈学情，匹配教学规划和重点内容。
          </div>
        </div>
        <div class="item">
          <img class="titleIcon" src="@/style/image/classroom/itemIcon2.png" />
          <div class="item-title">人机互动智能答疑</div>
          <div class="item-text">
            专业答疑知识库，对问题答案进行智能分类；自动检索问题，从知识库中匹配答疑结果。
          </div>
        </div>
        <div class="item">
          <img class="titleIcon" src="@/style/image/classroom/itemIcon3.png" />
          <div class="item-title">智能弹题学练同步</div>
          <div class="item-text">
            直播过程中关联知识点，智能弹题匹配练习；智能反馈测试结果和答案解析，强化知识点。
          </div>
        </div>
      </div>
    </div> -->
    <div class="content6">
      <div class="title" style="padding-top: 100px">
        <img class="titleIcon" src="@/style/image/classroom/titleIcon.png">
        <div>
          <span class="blue">OMO产品升级 &nbsp;</span>
          大数据教学
        </div>
      </div>
      <div class="content">
        <img class="teaching1" src="@/style/image/classroom/teaching1.png">
        <img class="teaching2" src="@/style/image/classroom/teaching2.png">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  async created() {},
  mounted: {}
}
</script>
<style lang="less" scoped>
.classroom {
  width: 100%;
  background: #fff;
  padding-bottom: 120px;
  /* 标题 */
  .title {
    font-size: 48px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 48px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .titleIcon {
      width: 26px;
      height: 26px;
      margin-right: 16px;
    }
    .blue {
      color: #2baaff;
    }
  }
  .content {
    width: 1080px;
    margin: 48px auto 0px;
    display: flex;
    justify-content: center;
  }
  .content1 {
    width: 100%;
    height: 480px;
    background: url('~@/style/image/classroom/isheaderBg2.png');
    background-size: 100% 100%;
  }
  .content2 {
    width: 100%;
    background: url('~@/style/image/classroom/headerBg2.png') no-repeat;
    padding-top: 60px;
    background-size: 100% 500px;
    .content {
      width: calc(1080px + 10px + 21px);
      height: calc(470px + 10px + 21px);
      background: url('~@/style/image/classroom/bgImg4.png') no-repeat;
      background-size: 100% 100%;
      margin: 36px auto 0px;
      display: flex;
      // justify-content: space-between;
      // padding: 0px 21px 0px 10px;
      .content-left {
        width: 620px;
        .title {
          font-size: 28px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
          text-align: center;
          padding: 32px 0px 0px 10px;
          // padding : 16px 0px 0px 198px;
        }
        .left-content {
          padding-left: 58px;
          margin-top: 68px;
          .item {
            display: flex;
            .itemIcon {
              width: 32px;
              height: 32px;
              margin-right: 12px;
            }
            .box {
              .box-title {
                font-size: 18px;
                font-family: Microsoft YaHei-Bold, Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                line-height: 18px;
              }
              .box-text {
                margin-top: 12px;
                font-size: 14px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                line-height: 14px;
              }
            }
          }
          .item-line {
            margin: 30px 0px 26px;
            width: 200px;
            height: 1px;
            background: linear-gradient(135deg, #eeeeee 0%, rgba(221, 221, 221, 0) 100%);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
          }
        }
      }
      .content-right {
        width: 460px;

        .title {
          font-size: 28px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #2aaaff;
          line-height: 28px;
          padding: 72px 21px 0px 0px;
          text-align: center;
          // padding : 16px 0px 0px 198px;
        }
        .right-content {
          display: flex;
          flex-wrap: wrap;
          margin-top: 48px;
          .item {
            width: 50%;
            .itemIcon {
              width: 90px;
              height: 90px;
              margin: 0px auto;
            }
            .text {
              text-align: center;
              font-size: 18px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              line-height: 18px;
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
  .content3 {
    .content {
      background: url('~@/style/image/classroom/bgImg5.png') no-repeat center / 1140px 550px;
      // background-size: 100% 100%;
      width: 100%;
      height: 662px;
      position: relative;
      .text {
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
      .box {
        width: 230px;
        height: 190px;
        position: absolute;
      }
      .box1 {
        top: 53px;
        left: 6vw;
        padding-top: 98px;
      }
      .box2 {
        top: 65px;
        right: 6vw;
        padding-top: 118px;
      }
      .box3 {
        bottom: 49px;
        left: 8vw;
        padding-top: 84px;
      }
      .box4 {
        bottom: 87px;
        right: 8vw;
        padding-top: 84px;
      }
    }
  }
  .content4 {
    .content {
      display: flex;
      justify-content: space-between;
      .content-item {
        width: 520px;
        height: 576px;
        background: #ffffff;
        box-shadow: 0px 0px 24px 1px rgba(87, 188, 255, 0.24);
        border-radius: 12px 12px 12px 12px;
        opacity: 1;
        padding: 40px 0px 0px;
        .item-title {
          width: 160px;
          height: 80px;
          background: linear-gradient(180deg, #eaebff 0%, rgba(234, 235, 255, 0) 100%);
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          margin: 0px auto;
          font-size: 28px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 28px;
          padding-top: 20px;
          text-align: center;
        }
        .item-tips {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 16px;
          text-align: center;
          margin-top: -16px;
        }
        .titleIcon {
          margin: 40px auto 0px;
          width: 240px;
          height: 240px;
        }
        .list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: 60px 122px 0px;
          .item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .item-icon {
              width: 18px;
              height: 18px;
              margin-right: 8px;
            }
            .text {
              font-size: 18px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #000000;
              line-height: 18px;
            }
          }
        }
      }
      .item2 {
        .item-title {
          background: linear-gradient(180deg, #e8f6ff 0%, rgba(232, 246, 255, 0) 100%);
        }
      }
    }
  }
  .content5 {
    .content {
      justify-content: space-between;
      .item {
        width: 340px;
        height: 340px;
        background: #ffffff;
        box-shadow: 0px 0px 24px 1px rgba(87, 188, 255, 0.24);
        border-radius: 12px 12px 12px 12px;
        opacity: 1;
        padding: 0px 40px;
        .titleIcon {
          width: 90px;
          height: 90px;
          margin: 48px auto 0px;
        }
        .item-title {
          margin-top: 40px;
          text-align: center;
          font-size: 22px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 22px;
        }
        .item-text {
          margin-top: 20px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
      }
    }
  }
  .content6 {
    .content {
      width: 1080px;
      height: 480px;
      background: #ffffff;
      box-shadow: 0px 0px 24px 1px rgba(87, 188, 255, 0.24);
      border-radius: 12px 12px 12px 12px;
      opacity: 1;
      align-items: center;
      padding: 0px 90px;
      justify-content: space-between;
      .teaching1 {
        width: 390px;
        height: 350px;
      }
      .teaching2 {
        width: 420px;
        height: 278px;
      }
    }
  }
}
</style>
